import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const ProjectPost = ({ data }) => {
  const post = data.markdownRemark;
  return (
    <Layout>
      <Helmet title={'Tom Beyer: ' + post.frontmatter.title} />
      <div>
        <h1 className="pageTitle">{post.frontmatter.title}</h1>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
      </div>
    </Layout>
  );
};

export default ProjectPost;

ProjectPost.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};

ProjectPost.defaultProps = {
  data: false,
};

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
